/*@tailwind base;*/

/**
 * Custom base styles
 */

* {
    /* Text rendering */
    text-rendering: optimizeLegibility;
    -o-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -webkit-text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
}

* :focus {
    outline: none !important;
}

html {
    font-size: 62.5%;
    font-family: 'Inter var', Roboto, Helvetica Neue, Arial, sans-serif;
    background-color: #121212;
}

body {
    font-size: 14px;
    line-height: 1.4;
    overflow-x: hidden;
    font-feature-settings: "salt";
}

html,
body {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
}

html,
body {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0;
    min-height: 100%;
    width: 100%;
    flex: 1 1 auto;
}

#root {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
}

h1,
.h1 {
    font-size: 24px;
}

h2,
.h2 {
    font-size: 20px;
}

h3,
.h3 {
    font-size: 16px;
}

h4,
.h4 {
    font-size: 15px;
}

h5,
.h5 {
    font-size: 13px;
}

h6,
.h6 {
    font-size: 12px;
}

.ps>.ps__rail-y,
.ps>.ps__rail-x {
    z-index: 99;
}

a[role=button] {
    text-decoration: none;
}

[role="tooltip"] {
    z-index: 9999;
}

.MuiModal-root {
    z-index: 9999;
}

.MuiAutocomplete-popper {
    z-index: 9999;
}

.MuiAutocomplete-popper ul {
    max-height: 200px !important;
}

.MuiFormHelperText-root.Mui-error {
    font-weight: bold;
}

.less-text {
    display: inline-block;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.crop-text {
    text-overflow: ellipsis;
    white-space: 'nowrap';
    overflow: hidden;
}

.print-style {

    div,
    span,
    p {
        font-size: 11px;
    }
}

.shipping-label {

    div,
    span,
    h5,
    p {
        font-weight: bold;
    }
}

.number-no-scroll {

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
        appearance: textfield;
    }
}


/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar:hover {
        width: 8px;
        height: 8px;
        background-color: rgba(0, 0, 0, 0.06);
    }

    ::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        border-radius: 20px;
    }

    ::-webkit-scrollbar-thumb:active {
        border-radius: 20px;
    }
}

form label {
    z-index: 99;
}

body.no-animate *,
body.no-animate *::before,
body.no-animate *::after {
    transition: none !important;
    animation: none !important;
}

button:focus {
    outline: none;
}

/* Removes webkit's autofill backgorund color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transitionDelay: 9999s;
    transitionProperty: background-color, color;
}

:focus {
    outline-color: transparent;
}

/*fullcalendar Fix*/
.fc-scrollgrid-section-liquid {
    height: 1px !important;
}

.custom-icon {
    flex-shrink: 0;
    cursor: pointer;
    color: white;
    width: 30px;
    height: 30px;
    height: 1px !important;
    padding: 0.2rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    min-width: 30px;
    min-height: 30px;
    font-size: 30px;
    line-height: 30px;
    border-radius: 0.8rem;
    --tw-bg-opacity: 1;
    background-color: rgb(33, 33, 33);
    margin-right: 1rem;
}

.cl-icon {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    width: 30px;
    height: 30px;
    height: 1px !important;
    border-radius: 1rem;
    padding: 0.2rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    min-width: 30px;
    min-height: 30px;
    font-size: 30px;
    line-height: 30px;
    margin-right: 1rem;
    color: rgb(244, 67, 54);
    background-color: rgb(255, 255, 255);
    border: 1px solid black;
}

.action-badge>span {
    padding: 0px !important;
    right: 28%;
    background-color: #B3261E;
    top: 20%;
}

.comment-badge>span {
    margin-left: -33px;
    margin-top: -6px;
}

.product-name>span {
    width: 27rem !important;
    cursor: pointer;
}